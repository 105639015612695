import { InstagramLogoIcon, TwitterLogoIcon } from '@radix-ui/react-icons';
import { BaseLink as Link } from '@spikemark/ui/components/navigation/entity-links';
import { FC } from 'react';

export const Footer: FC = () => {
  const containerClassName = 'flex flex-col';
  const sectionWrapperClassName = 'text-sm leading-loose font-light mb-8 space-y-1 md:mb-0';
  const headerClassName = 'uppercase text-base font-bold mb-2 text-[#A7ADB5] md:mb-3';

  return (
    <div className="flex flex-col bg-primary-dark text-white px-12 py-8 text-sm md:px-24 md:py-14 md:flex-row md:justify-between print:hidden">
      <div className={containerClassName}>
        <h1 className={headerClassName}>Information</h1>
        <div className={sectionWrapperClassName}>
          <Link className="block" href="/rankings">
            National Collegiate Golf Ranking
          </Link>
          <Link className="block" href="/information/faqs">
            Frequently Asked Questions
          </Link>
          <Link className="block" href="/information/privacy">
            Privacy Policy
          </Link>
          <Link className="block" href="/information/tos">
            Terms of Service
          </Link>
        </div>
      </div>

      <div className={containerClassName}>
        <h1 className={headerClassName}>Company</h1>
        <div className={sectionWrapperClassName}>
          <Link className="block" href="/information/about">
            About us
          </Link>
          <Link className="block" href="/information/media">
            Press
          </Link>
          <a target="_blank" href="https://clippd.com">
            Clippd.com
          </a>
        </div>
      </div>

      <div className={containerClassName}>
        <h1 className={headerClassName}>Contact Us</h1>
        <div className={sectionWrapperClassName}>
          <p>Clippd USA Ltd</p>
          <p>
            mail:{' '}
            <a href="mailto:support@clippd.io" className="underline">
              support@clippd.io
            </a>
          </p>
        </div>
      </div>

      <div className={containerClassName}>
        <h1 className={headerClassName}>Follow Us</h1>
        <div className="flex space-x-3 mb-4">
          <Link href="https://www.instagram.com/clippdscoreboard">
            <InstagramLogoIcon className="w-6 h-6 text-white" />
          </Link>
          <Link href="https://twitter.com/clippdscore">
            <TwitterLogoIcon className="w-6 h-6 text-white" />
          </Link>
        </div>
      </div>
    </div>
  );
};
