'use client';

import { FC } from 'react';
import { Capacitor } from '@capacitor/core';
import { NativeFooter } from '@spikemark/ui/components/native-footer';
import { Footer } from './footer';

export const AppFooter: FC = () => {
  return Capacitor.isNativePlatform() ? <NativeFooter /> : <Footer />;
};
