'use client';

import { FC, PropsWithChildren } from 'react';
import { IClientConfig, ClientConfigProvider, ClientCacheProvider } from '@spikemark/core';
import { GoogleAnalytics } from '@spikemark/ui/shell/google-analytics';

export const AppProvider: FC<PropsWithChildren<{ clientConfig: IClientConfig }>> = ({
  children,
  clientConfig,
}) => {
  return (
    <ClientConfigProvider value={clientConfig}>
      <GoogleAnalytics />
      <ClientCacheProvider>{children}</ClientCacheProvider>
    </ClientConfigProvider>
  );
};
