'use client';

import { ChevronLeftIcon } from '@radix-ui/react-icons';
import { usePathname, useRouter } from 'next/navigation';
import { FC } from 'react';
import { Button } from '../components/ui/button';

export const BackButton: FC = () => {
  const pathname = usePathname();
  const { back } = useRouter();

  return pathname === '/' || pathname?.startsWith('/results') ? null : (
    <Button variant="link" className="text-base -ml-4" onClick={() => back()} type="button">
      <div className="flex items-center space-x-1">
        <ChevronLeftIcon />
        <span className="block">Back</span>
      </div>
    </Button>
  );
};
