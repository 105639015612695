import { FC } from 'react';
import Script from 'next/script';
import { useClientConfig } from '@spikemark/core';

export const GoogleAnalytics: FC = () => {
  const {
    googleAnalytics: { gTag },
  } = useClientConfig();

  return (
    process.env.NODE_ENV === 'production' &&
    gTag && (
      <>
        <Script src={`https://www.googletagmanager.com/gtag/js?id=${gTag}`} />
        <Script id="google-analytics">
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gTag}');
      `}
        </Script>
      </>
    )
  );
};
