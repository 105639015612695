import(/* webpackMode: "eager", webpackExports: ["AppFooter"] */ "/app/apps/web/components/app-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppHeaderContent"] */ "/app/apps/web/components/app-header-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LiveTournamentsHeaderPrompt"] */ "/app/apps/web/components/live-tournaments-header-prompt.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/app/apps/web/lib/app-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MetadataProvider"] */ "/app/apps/web/providers/metadata-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts/index.ts\",\"import\":\"Gabarito\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"variable\":\"--fontGabarito\",\"weight\":[\"500\",\"600\"]}],\"variableName\":\"fontGabarito\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts/index.ts\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--fontNunito\",\"weight\":[\"400\",\"600\"]}],\"variableName\":\"fontNunito\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts/index.ts\",\"import\":\"Roboto_Flex\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"variable\":\"--fontRoboto\",\"weight\":[\"400\",\"600\"]}],\"variableName\":\"fontRoboto\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/web/styles/style.css");
;
import(/* webpackMode: "eager" */ "/app/packages/core/src/cache/client-cache-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/core/src/config/client-config-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/app/packages/ui/components/ui/tooltip.tsx");
