'use client';

import { Capacitor } from '@capacitor/core';
import { Header } from '@spikemark/ui/shell/header';
import { Route } from '@spikemark/ui/shell/navigation/nav-links';
import { FC } from 'react';

export const AppHeaderContent: FC<{ routes: Route[] }> = ({ routes }) => {
  return (
    <div className="print:hidden">
      <Header topLevelNavigation={routes} isCapacitor={Capacitor.isNativePlatform()} />
    </div>
  );
};
