'use client';

import { AnimatePresence, MotionConfig, motion as m } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';
import { Ease } from '../../constants/motion';

export const MobileNavContainer: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <MotionConfig transition={{ ease: Ease.Out, duration: 0.23 }}>
      <AnimatePresence>
        {isOpen && (
          <>
            <m.div
              key="backdrop"
              className="fixed inset-0 bg-black"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.7 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsOpen(false)}
            />
            <div key="menu" className="fixed top-0 h-screen right-0 z-50" style={{ width: '50vw' }}>
              <m.div
                className="absolute inset-0 bg-primary"
                initial={{ opacity: 0.5, x: '50vw' }}
                animate={{ opacity: 1, x: '0%' }}
                exit={{ opacity: 0.5, x: '50vw' }}
                style={{ width: '50vw' }}
              >
                <div className="mx-4 my-16 space-y-2">{children}</div>
              </m.div>
            </div>
          </>
        )}
      </AnimatePresence>
      <MobileNavButton isOpen={isOpen} setIsOpen={setIsOpen} />
    </MotionConfig>
  );
};

const MobileNavButton: FC<{
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isOpen, setIsOpen }) => (
  <button className="absolute top-0 right-3 mt-3.5 z-50" onClick={() => setIsOpen((prev) => !prev)}>
    <m.svg width="30" height="30" animate={{ fill: isOpen ? '#fff' : '#000' }}>
      <g transform="translate(5, 5)">
        <m.rect
          width={20}
          height={3}
          variants={{
            open: {
              rotate: 45,
              x: 0,
              y: 7,
            },
            closed: {
              rotate: 0,
              x: 0,
              y: 0,
            },
          }}
          animate={isOpen ? 'open' : 'closed'}
        />
        <m.rect y={7} width={20} height={3} animate={{ opacity: isOpen ? 0 : 1 }} />
        <m.rect
          y={14}
          width={20}
          height={3}
          variants={{
            open: {
              rotate: -45,
              x: 0,
              y: -7,
            },
            closed: {
              rotate: 0,
              x: 0,
              y: 0,
            },
          }}
          animate={isOpen ? 'open' : 'closed'}
        />
      </g>
    </m.svg>
  </button>
);
