import { CSSProperties, FC, PropsWithChildren } from 'react';

type DesktopNavContainerProps = PropsWithChildren<{
  style?: CSSProperties;
}>;

export const DesktopNavContainer: FC<DesktopNavContainerProps> = ({ children, style }) => (
  <div className="flex items-center h-full" style={style}>
    {children}
  </div>
);
