'use client';

import { LiveBadge } from '@spikemark/ui/shell/badges/live-badges';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

const IGNORED_PATHS = [
  'results',
  'tournaments',
  'schedule',
  'rankings',
  'teams',
  'players',
  'articles',
];

export const LiveTournamentsHeaderPrompt = () => {
  const pathname = usePathname();
  if (IGNORED_PATHS.some((path) => pathname?.includes(path))) {
    return null;
  }
  return (
    <div className="bg-blue-dark w-full flex items-center justify-center text-white p-2 space-x-4">
      <LiveBadge status="live" />
      <p className="text-base">
        Tournaments in progress.
        <Link href="/results/current" className="underline ml-2">
          View Live Scores
        </Link>
      </p>
    </div>
  );
};
