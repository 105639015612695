import classNames from 'classnames';
import Link, { LinkProps as _LinkProps } from 'next/link';
import { FC, PropsWithChildren } from 'react';
import { isMappedPlayer } from '../../utils/player';
import { ArrowUpRight } from 'lucide-react';

type LinkProps = _LinkProps & {
  className?: string;
  disabled?: boolean;
  block?: boolean;
  variant?: 'default' | 'secondary';
  params?: Record<string, string>;
  suppressChevron?: boolean;
};

type EntityLinkProps<T> = PropsWithChildren<T & Omit<LinkProps, 'href'>>;

export const EntityLink: FC<PropsWithChildren<LinkProps>> = ({
  children,
  className,
  variant = 'default',
  block,
  disabled,
  params,
  href,
  suppressChevron,
  ...props
}) => {
  if (disabled) {
    return children;
  }

  const searchParams = params ? `?${new URLSearchParams(params).toString()}` : '';

  return (
    <BaseLink
      {...props}
      className={classNames(
        className,
        'items-center leading-none hover:text-clippd-pink transition-colors duration-200',
        {
          'inline-flex': !block,
          flex: block,
          'text-primary dark:text-white': variant === 'default',
          'text-secondary dark:text-coolgray-mid': variant === 'secondary',
        }
      )}
      href={`${href}${searchParams}`}
    >
      <div className="max-w-[260px]">{children}</div>
      {suppressChevron ? null : <ArrowUpRight size={20} className="inline-block ml-2 -mt-0.5" />}
    </BaseLink>
  );
};

export const PlayerLink: FC<EntityLinkProps<{ playerId?: string }>> = ({
  playerId,
  children,
  ...linkProps
}) => {
  if (!isMappedPlayer(playerId)) {
    return <span className={linkProps.className}>{children}</span>;
  }

  return (
    <EntityLink href={`/players/${playerId}`} {...linkProps}>
      {children}
    </EntityLink>
  );
};

export const TeamLink: FC<EntityLinkProps<{ schoolId: string }>> = ({
  schoolId,
  children,
  ...linkProps
}) => {
  return (
    <EntityLink href={`/teams/${schoolId}`} {...linkProps}>
      {children}
    </EntityLink>
  );
};

export const BaseLink: FC<PropsWithChildren<any>> = ({ children, ...restprops }) => {
  return (
    <Link prefetch={false} {...restprops}>
      {children}
    </Link>
  );
};
