import { FC } from 'react';
import { BreakpointSwitch } from '../components/breakpoint-switch';
import { HeaderDesktop } from './header-desktop';
import { HeaderMobile } from './header-mobile';
import { Route } from './navigation/nav-links';

interface HeaderProps {
  topLevelNavigation: Route[];
  isCapacitor?: boolean;
}

export const Header: FC<HeaderProps> = ({ topLevelNavigation, isCapacitor }) => {
  return (
    <BreakpointSwitch
      breakpoint="lg"
      fallback={<HeaderMobile topLevelNavigation={topLevelNavigation} isCapacitor={isCapacitor} />}
    >
      <HeaderDesktop topLevelNavigation={topLevelNavigation} />
    </BreakpointSwitch>
  );
};
